import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

import { App } from "./App";

const container = document.getElementById("app");
const root = createRoot(container);
root.render(
    <StrictMode>
        <App apiBase="https://api.natcapresearch.com/share/v1" />
    </StrictMode>,
);
