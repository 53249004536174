import React, { Fragment, useState, useEffect } from "react";
import { Dashboard } from "@uppy/react";
import { createGetUploadParameters, createUppy } from "./uppy";

type AppProps = {
    apiBase: string;
};

function getJwtSub(jwt: string | null): string {
    if (!jwt) {
        return "";
    }
    try {
        const jwtPayload = JSON.parse(atob(jwt.split(".")[1]));
        const sub = jwtPayload.sub;
        return sub;
    } catch {
        return "";
    }
}

function useJwt() {
    function getJwt() {
        // Parse the URL fragment as a query string and extract the JWT using the URLSearchParams API.
        const params = new URLSearchParams(window.location.hash.slice(1));
        return params.get("t");
    }
    function updateJwt() {
        setJwt(getJwt());
    }

    const [jwt, setJwt] = useState(getJwt());
    useEffect(() => {
        window.addEventListener("hashchange", updateJwt);
        return () => {
            window.removeEventListener("hashchange", updateJwt);
        };
    });
    return jwt;
}

export function App(props: AppProps) {
    const jwt = useJwt();
    const [uppy] = useState(() =>
        createUppy({
            apiBase: props.apiBase,
            jwt: jwt,
        }),
    );
    window.uppy = uppy;

    useEffect(() => {
        const plugin = uppy.getPlugin("AwsS3");
        if (!plugin) return;
        plugin.setOptions({
            getUploadParameters: createGetUploadParameters({
                apiBase: props.apiBase,
                jwt: jwt,
            }),
        });
    }, [jwt]);

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 4000);
    }, [jwt]);
    // const store = useSyncExternalStore(
    //     (cb) => {
    //         return uppy.store.subscribe(cb);
    //     },
    //     () => {
    //         return uppy.store.getState();
    //     },
    // );

    const showDashboard = !loading && jwt;
    const showError = !loading && !jwt;
    const showLoading = !showDashboard && !showError;

    return (
        <Fragment>
            <header>
                <div className="frame">
                    <h1>
                        <strong>natcap</strong> | share
                    </h1>
                    <h2>Easily & securely share data with us</h2>
                </div>
                <div className="profile divider header-footer">
                    <div className="frame">
                        {getJwtSub(jwt) || "Made with ♥ in UK"}
                    </div>
                </div>
            </header>
            <section id="main" className="shadow-elevation-medium">
                <main>
                    {showLoading || showError ? (
                        <div className="frame message shadow-elevation-medium">
                            <div
                                className={`link ${
                                    showLoading
                                        ? "rotates"
                                        : "rotates rotates-fixed"
                                }`}
                            ></div>
                            {showError ? (
                                <p>
                                    Your magic link is missing or invalid.
                                    Please{" "}
                                    <a href="mailto:support@natcapresearch.com">
                                        contact us
                                    </a>{" "}
                                    to get a new one.
                                </p>
                            ) : (
                                <p>Loading...</p>
                            )}
                        </div>
                    ) : null}
                    {showDashboard ? (
                        <Dashboard
                            uppy={uppy}
                            className="uppy-Container shadow-elevation-low"
                            width="100%"
                            height="100%"
                            proudlyDisplayPoweredByUppy={false}
                            autoOpenFileEditor={true}
                            singleFileFullScreen={false}
                            metaFields={[
                                {
                                    id: "name",
                                    name: "Name",
                                    placeholder: "file name",
                                },
                                {
                                    id: "description",
                                    name: "Description",
                                    placeholder: "What is this file?",
                                },
                                {
                                    id: "pii",
                                    name: "Personally Identifiable Information",
                                    placeholder:
                                        "Describe any PII in this file, if applicable",
                                },
                            ]}
                        />
                    ) : null}
                </main>
                <footer>
                    <p>
                        Your data is transferred directly to encrypted cloud
                        storage over a secure connection. It does not pass
                        through any intermediaries.
                    </p>
                    <p>
                        Need help?{" "}
                        <a href="mailto:support@natcapresearch.com">
                            Contact us
                        </a>
                        .
                    </p>
                </footer>
            </section>
        </Fragment>
    );
}
