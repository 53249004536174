import Uppy, { debugLogger } from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";

export function createGetUploadParameters(config: { apiBase; jwt }) {
    const { apiBase, jwt } = config || {};

    return async function getUploadParameters(file) {
        let response;
        try {
            response = await fetch(`${apiBase}/sign/s3.json`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwt}`,
                },
                body: JSON.stringify({
                    file: file.name,
                    "content-type": file.type,
                    metadata: {
                        name: file.meta.name,
                        description: file.meta.description,
                        pii: file.meta.pii,
                    },
                }),
            });
        } catch (e) {
            throw new Error("Unable to fetch secure upload parameters");
        }

        if (!response.ok) {
            throw new Error("Unable to fetch secure upload parameters");
        }

        const data = await response.json();

        return {
            method: data.method,
            url: data.url,
            fields: {}, // For presigned PUT uploads, this should be left empty.
            // Provide content type header required by S3
            headers: {
                "Content-Type": `${file.type}`,
            },
        };
    };
}
export function createUppy(config: { apiBase; jwt }) {
    const uppy = new Uppy({
        logger: debugLogger,
        restrictions: {
            requiredMetaFields: ["name", "description"],
        },
    }).use(AwsS3, {
        id: "AwsS3",
        shouldUseMultipart: false,
        getUploadParameters: createGetUploadParameters(config),
    });

    uppy.on("complete", (result) => {
        if (result.failed.length === 0) {
            console.log("Upload successful üòÄ");
        } else {
            console.warn("Upload failed üòû");
        }
        console.log("successful files:", result.successful);
        console.log("failed files:", result.failed);
    });

    return uppy;
}
